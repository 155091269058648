import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomNode from "./CustomNode";
import Input from "~/components/elements/Input";
import { FlowContext } from "~/contexts/flow-context";
import Button from "~/components/elements/Button";
import { LinkIcon, TrashIcon } from "@heroicons/react/20/solid";
import InputSelect from "../../../elements/InputSelect";

export default function HttpRequestNode(props) {
  const { t } = useTranslation();

  const { selected, data } = props;
  const { id, options } = data;

  const {
    http_method,
    http_url,
    http_headers = [],
    http_params = [],
  } = options;

  const { editAction } = useContext(FlowContext);

  const handleActionChange = (key, value) => {
    editAction(id, {
      options: { ...options, [key]: value },
    });
  };

  return (
    <CustomNode
      selected={selected}
      help={
        "https://inro.notion.site/Send-an-HTTP-request-from-a-scenario-5ccc211fb44e4514898028187f706d72"
      }
    >
      <div
        className={`p-3 bg-white border-2 rounded-2xl nodrag ring-1 ring-indigo-400 ring-offset-1 ring-opacity-0 hover:ring-opacity-100 transition-all space-y-2 ${
          selected ? "ring-opacity-100 z-50" : null
        }`}
      >
        <div className="text-md font-medium">
          {t("automation.scenarios.actions.http_request.title")}
        </div>
        <div className="text-2sm text-darker-gray">
          {t("automation.scenarios.actions.http_request.description")}
        </div>
        <InputSelect
          options={["GET", "POST", "PATCH", "PUT", "DELETE"]}
          value={http_method}
          onChange={(value) => handleActionChange("http_method", value)}
        />

        <Input
          icon={LinkIcon}
          value={http_url}
          onChange={(value) => handleActionChange("http_url", value)}
          type="url"
          placeholder={t(
            "automation.scenarios.actions.http_request.url_placeholder",
          )}
        />
        {/* Headers */}
        {http_headers?.length > 0 && (
          <div className="text-2sm font-medium">
            {t("automation.scenarios.actions.http_request.headers")}
          </div>
        )}
        {http_headers?.map((header, index) => (
          <div className="flex items-center space-x-2">
            <div className="grid grid-cols-2 gap-2 flex-grow">
              <Input
                value={header.key}
                onChange={(value) =>
                  handleActionChange("http_headers", [
                    ...http_headers.slice(0, index),
                    { ...header, key: value },
                    ...http_headers.slice(index + 1),
                  ])
                }
                type="text"
                placeholder={t(
                  "automation.scenarios.actions.http_request.header_key",
                )}
                autoFocus={index === http_headers.length - 1 && !header.key}
              />
              <Input
                value={header.value}
                onChange={(value) =>
                  handleActionChange("http_headers", [
                    ...http_headers.slice(0, index),
                    { ...header, value: value },
                    ...http_headers.slice(index + 1),
                  ])
                }
                type="text"
                placeholder={t(
                  "automation.scenarios.actions.http_request.header_value",
                )}
              />
            </div>
            <Button
              icon={TrashIcon}
              size="small"
              onClick={() =>
                handleActionChange(
                  "http_headers",
                  http_headers.filter((_, i) => i !== index),
                )
              }
            />
          </div>
        ))}
        <Button
          label={t("automation.scenarios.actions.http_request.add_header")}
          size="small"
          className="inline-flex mr-2"
          onClick={() =>
            handleActionChange("http_headers", [
              ...http_headers,
              { key: "", value: "" },
            ])
          }
        />
        {/* Body */}
        {http_params?.length > 0 && (
          <div className="text-2sm font-medium mt-2">
            {t("automation.scenarios.actions.http_request.body")}
          </div>
        )}
        {http_params?.map((header, index) => (
          <div className="flex items-center space-x-2 mt-2">
            <div className="grid grid-cols-2 gap-2 flex-grow">
              <Input
                value={header.key}
                onChange={(value) =>
                  handleActionChange("http_params", [
                    ...http_params.slice(0, index),
                    { ...header, key: value },
                    ...http_params.slice(index + 1),
                  ])
                }
                type="text"
                placeholder={t(
                  "automation.scenarios.actions.http_request.param_key",
                )}
                autoFocus={index === http_params.length - 1 && !header.key}
              />
              <Input
                value={header.value}
                onChange={(value) =>
                  handleActionChange("http_params", [
                    ...http_params.slice(0, index),
                    { ...header, value: value },
                    ...http_params.slice(index + 1),
                  ])
                }
                type="text"
                placeholder={t(
                  "automation.scenarios.actions.http_request.param_value",
                )}
              />
            </div>
            <Button
              icon={TrashIcon}
              size="small"
              onClick={() =>
                handleActionChange(
                  "http_params",
                  http_params.filter((_, i) => i !== index),
                )
              }
            />
          </div>
        ))}
        {http_method != "GET" && (
          <Button
            label={t("automation.scenarios.actions.http_request.add_param")}
            size="small"
            className="inline-flex mt-2"
            onClick={() =>
              handleActionChange("http_params", [
                ...http_params,
                { key: "", value: "" },
              ])
            }
          />
        )}
      </div>
    </CustomNode>
  );
}
