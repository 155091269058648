import React from "react";
import { Handle, Position } from "@xyflow/react";
import NodeButtons from "../NodeButtons";

export default function CustomNode(props) {
  const {
    first = false,
    last = false,
    children,
    selected,
    help = null,
    buttons = [],
    noEdit,
    width = 280,
    icon: Icon,
  } = props;

  return (
    <>
      {first ? null : (
        <Handle
          type="target"
          position={Position.Top}
          className="opacity-0"
          isConnectable={false}
        />
      )}
      <div
        className="flex flex-col items-center space-y-2 group"
        style={{ width: `${width}px` }}
      >
        {Icon && (
          <Icon className="w-6 absolute -left-10 top-1/2 -translate-y-1/2" />
        )}
        {children}
        {!noEdit && (
          <NodeButtons selected={selected} buttons={buttons} help={help} />
        )}
      </div>
      {last ? null : (
        <Handle
          type="source"
          position={Position.Bottom}
          className="opacity-0"
          isConnectable={false}
        />
      )}
    </>
  );
}
