import React, { useEffect, useState } from "react";
import Alert from "../components/shared/Alert";
import Dialog from "../components/shared/Dialog";
import BottomMenu from "../components/shared/BottomMenu";

import { useMediaQuery } from "react-responsive";

import { UIContext } from "~/contexts/ui-context";
import reactNativeMessage from "~/utils/reactNativeMessage";
import BottomTooltip from "../components/utils/BottomTooltip";
import { useTranslation } from "react-i18next";
import VideoDrawer from "../components/shared/VideoDrawer";
import { isObject, isString } from "lodash";

export default function UIProvider(props) {
  const [alert, setAlert] = useState(null);
  const [dialog, showDialog] = useState(null);
  const [menu, showMenu] = useState(null);
  const [tooltip, showTooltip] = useState(null);
  const [video, showVideo] = useState(null);

  const { t } = useTranslation();

  // Alert
  const showAlert = (alert) => {
    if (isString(alert)) {
      setAlert({
        title: alert,
        hideCancel: true,
        confirm: t("shared.ok"),
      });
    } else if (isObject(alert)) {
      setAlert(alert);
    }
  };

  const showPrompt = (
    title,
    confirm = () => {},
    cancel = () => {},
    message = null,
  ) => {
    setAlert({
      title,
      message,
      confirm: t("shared.yes"),
      cancel: t("shared.no"),
      onSubmit: confirm,
      onCancel: cancel,
    });
  };

  // Error alert
  const showError = (error_key) => {
    showAlert({
      title: t("errors." + error_key),
      hideCancel: true,
      confirm: t("shared.ok"),
    });
  };

  // Keyboard height
  const [keyboardHeight, setKeyboardHeight] = useState(0);
  useEffect(() => {
    const handleKeyboardShow = (e) => setKeyboardHeight(e.detail);
    window.addEventListener("keyboardEvent", handleKeyboardShow);
    return () => {
      window.removeEventListener("keyboardEvent", handleKeyboardShow);
    };
  }, []);

  // App notifications
  const [notificationsGranted, setNotificationsGranted] = useState(false);
  const checkNotifications = () => {
    reactNativeMessage({ checkNotifications: true });
  };
  useEffect(() => {
    checkNotifications();
    const handleNotificationsGranted = (e) => {
      setNotificationsGranted(e.detail);
    };
    window.addEventListener("notificationsGranted", handleNotificationsGranted);
    return () => {
      window.removeEventListener(
        "notificationsGranted",
        handleNotificationsGranted,
      );
    };
  }, []);

  // App state
  useEffect(() => {
    const handleAppStateChange = (e) => {
      if (e.detail === "active") {
        checkNotifications();
      }
    };
    window.addEventListener("appStateChange", handleAppStateChange);
  }, []);

  // Media queries
  const isMediaSm = useMediaQuery({ minWidth: 640 });
  const isMediaMd = useMediaQuery({ minWidth: 768 });
  const isMediaLg = useMediaQuery({ minWidth: 1024 });
  const isMediaXl = useMediaQuery({ minWidth: 1280 });
  const isMedia2xl = useMediaQuery({ minWidth: 1536 });

  const values = {
    alert,
    showAlert,
    showPrompt,
    showError,
    dialog,
    showDialog,
    menu,
    showMenu,
    showTooltip,
    showVideo,
    keyboardHeight,
    checkNotifications,
    notificationsGranted,
    isMediaSm,
    isMediaMd,
    isMediaLg,
    isMediaXl,
    isMedia2xl,
  };

  return (
    <UIContext.Provider value={values}>
      {props.children}
      {alert && (
        <Alert
          {...alert}
          keyboardHeight={keyboardHeight}
          onClose={() => setAlert(null)}
        />
      )}
      {dialog && (
        <Dialog
          {...dialog}
          keyboardHeight={keyboardHeight}
          onClose={() => showDialog(null)}
        />
      )}
      {menu && <BottomMenu {...menu} onClose={() => showMenu(null)} />}
      {tooltip && (
        <BottomTooltip text={tooltip} onClose={() => showTooltip(null)} />
      )}
      {video && <VideoDrawer {...video} onClose={() => showVideo(false)} />}
    </UIContext.Provider>
  );
}
