import React, { useContext, useEffect, useState } from "react";
import { AutomationContext } from "../../../contexts/automation-context";
import Table from "../../elements/table/Table";
import { useTranslation } from "react-i18next";
import RelativeDate from "../../utils/RelativeDate";
import Button from "../../elements/Button";
import { DocumentArrowDownIcon } from "@heroicons/react/20/solid";
import renderQuestionReplyValue from "./renderQuestionReplyValue";

export default function QuestionsSurveyResults(props) {
  const { t } = useTranslation();

  const { survey, questions } = props;

  const { loadSurveyResults } = useContext(AutomationContext);

  const [results, setResults] = useState([]);

  // Pagination

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState(null);
  const [totalPages, setTotalPages] = useState(null);

  useEffect(async () => {
    if (survey.id) {
      const res = await loadSurveyResults(survey.id, {
        page: currentPage,
        per_page: perPage,
      });
      setResults(res.results);
      setTotalCount(res.total_count);
      setTotalPages(res.total_pages);
    }
  }, [survey.id, currentPage, perPage]);

  const metrics = [
    {
      label: t("surveys.metrics.sent"),
      value: survey.metrics?.sent || 0,
    },
    {
      label: t("surveys.metrics.completed"),
      value: survey.metrics?.completed || 0,
    },
    {
      label: t("surveys.metrics.completion_rate"),
      value: `${Math.round((100 * (survey.metrics?.completed || 0)) / (survey.metrics?.sent || 1))}%`,
    },
  ];

  const resultColumns = [
    {
      label: t("contacts.columns.username"),
      name: "username",
      accessor: (result) => (
        <div className="flex items-center space-x-2">
          {result?.contact?.profile_picture && (
            <div className="w-5 h-5">
              <img
                className="block w-5 h-5 rounded-full"
                src={result?.contact?.profile_picture}
              />
            </div>
          )}
          <div className="font-medium text-black">
            {result?.contact.username}
          </div>
        </div>
      ),
    },
    {
      label: t("contacts.columns.name"),
      name: "name",
      accessor: (result) => (
        <span className="whitespace-nowrap">
          {result?.contact.real_name || result?.contact.name}
        </span>
      ),
    },
    {
      label: t("shared.date"),
      name: "replied_at",
      accessor: (result) => (
        <div className="whitespace-nowrap">
          <RelativeDate date={result.converted_at} />
        </div>
      ),
    },
    ...questions.map((question) => ({
      label: question.title,
      name: question.id,
      accessor: (result) => {
        const reply = result.survey_question_replies.find(
          (a) => parseInt(a.survey_question_id) === parseInt(question.id),
        );
        return renderQuestionReplyValue(reply?.value);
      },
    })),
  ];

  return (
    <div className="p-4 space-y-4">
      <div className="flex justify-between space-x-8 items-end">
        <div className="grid grid-cols-3 gap-4 flex-grow max-w-[380px] px-4 py-3 bg-neutral-50 rounded-xl">
          {metrics.map((metric) => (
            <div className="space-y-1">
              <div className="text-sm text-gray-500">{metric.label}</div>
              <div className="text-xl font-medium">{metric.value}</div>
            </div>
          ))}
        </div>
        <Button
          label={t("surveys.questions.results.export")}
          icon={DocumentArrowDownIcon}
          target="_blank"
          href={`/export/survey_results?survey_id=${survey.id}`}
        />
      </div>
      <Table
        items={results}
        columns={resultColumns}
        defaultPerPage={10}
        isPaginated
        {...{
          perPage,
          setPerPage,
          currentPage,
          setCurrentPage,
          totalCount,
          totalPages,
        }}
      />
    </div>
  );
}
