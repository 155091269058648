import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "~/utils/classNames";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { FaceSmileIcon } from "@heroicons/react/24/outline";
import { useDebounce } from "react-use";
import { isNumber } from "lodash";
import CustomEmojiPicker from "~/components/shared/CustomEmojiPicker";

export default function Input(props) {
  const { t } = useTranslation();

  const {
    label,
    name,
    type = "text",
    placeholder,
    value = "",
    onChange = () => {},
    changeOnBlur = false,
    description = null,
    optional = false,
    size = "base",
    icon: Icon = null,
    autoFocus = false,
    readOnly = false,
    clearButton = false,
    className = "",
    inputClassName = "",
    debounce = false,
    leadingAddon = null,
    trailingAddon = null,
    onFocus = () => {},
    onBlur = () => {},
    maxLength = null,
    emojiPicker = false,
    disabled = false,
    ...passthroughProps
  } = props;

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const [localValue, setLocalValue] = useState(value || "");

  const handleInputChange = (evt) => {
    updateLocalValue(evt.target.value);
  };

  const handleInputBlur = (evt) => {
    if (changeOnBlur) {
      if (value?.toString() !== localValue?.toString()) {
        onChange(localValue);
      }
    }
    onBlur(evt);
  };

  const updateLocalValue = (newValue) => {
    if (maxLength) {
      setLocalValue(newValue.slice(0, maxLength));
    } else {
      setLocalValue(newValue);
    }
  };

  const handleLocalValueChange = () => {
    if (changeOnBlur) return;
    if (value?.toString() !== localValue?.toString()) {
      onChange(localValue);
    }
  };

  const debounceTime = useMemo(
    () => (isNumber(debounce) ? debounce : 300),
    [debounce],
  );

  debounce
    ? useDebounce(handleLocalValueChange, debounceTime, [localValue])
    : useEffect(handleLocalValueChange, [localValue]);

  useEffect(() => {
    if (value == null) {
      onChange("");
      setLocalValue("");
    } else if (value?.toString() != localValue?.toString()) {
      setLocalValue(value);
    }
  }, [value]);

  const inputElementProps = {
    type,
    name,
    id: name,
    className: "input-element",
    placeholder,
    value: localValue || "",
    onChange: handleInputChange,
    onBlur: handleInputBlur,
    autoFocus,
    readOnly,
    onFocus,
    disabled,
    ...passthroughProps,
  };

  return (
    <div
      className={classNames("flex flex-col items-stretch space-y-1", className)}
    >
      {label ? (
        <div className="flex justify-between">
          <label htmlFor={name} className="input-label">
            {label}
          </label>
          {optional ? (
            <span
              className="text-xs mt-1 leading-tight text-muted"
              id="email-optional"
            >
              {t("shared.optional")}
            </span>
          ) : null}
        </div>
      ) : null}
      <div
        className={classNames("relative", type == "textarea" && "flex-grow")}
      >
        <div
          className={classNames(
            "input",
            `input-${size}`,
            disabled && "pointer-events-none opacity-50",
            inputClassName,
          )}
        >
          {Icon ? <Icon className="input-icon" aria-hidden="true" /> : null}
          {leadingAddon ? (
            <span className="flex-shrink-0 flex select-none items-center pl-1.5 text-gray-500 sm:text-sm whitespace-nowrap">
              {leadingAddon}
            </span>
          ) : null}
          {type == "textarea" ? (
            <textarea {...inputElementProps}></textarea>
          ) : (
            <input {...inputElementProps} />
          )}
          {emojiPicker && (
            <FaceSmileIcon
              className="flex-shrink-0 w-5 text-neutral-500 hover:text-neutral-800 transition-opacity cursor-pointer"
              onClick={() => setShowEmojiPicker(true)}
            />
          )}
          {clearButton && value ? (
            <button
              type="button"
              className="flex-shrink-0 flex items-center justify-center rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
              onClick={() => onChange("")}
            >
              <XCircleIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </button>
          ) : null}
          {trailingAddon ? (
            <span className="flex-shrink-0 flex select-none items-center pr-1.5 text-gray-500 sm:text-sm whitespace-nowrap">
              {trailingAddon}
            </span>
          ) : null}
        </div>
        {emojiPicker && showEmojiPicker && (
          <CustomEmojiPicker
            onChange={(emoji) => updateLocalValue(localValue + emoji)}
            onClose={() => setShowEmojiPicker(false)}
          />
        )}
      </div>
      {description ? (
        <p className="text-sm text-gray-500" id={`${name}-description`}>
          {description}
        </p>
      ) : null}
    </div>
  );
}
