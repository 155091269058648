import React, { useEffect, useRef } from "react";
import TablePagination from "./TablePagination";
import Loader from "~/components/utils/Loader";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";

const perPageOptions = [10, 25, 50, 100];

export default function Table(props) {
  const {
    loading,
    columns = [],
    items = [],
    emptyState,
    isSelected = () => false,
    onRowClick = () => {},
    onRowRender = () => {},
    children,
    sortColumn,
    sortDirection,
    isPaginated = false,
    perPage,
    setPerPage,
    currentPage,
    setCurrentPage,
    totalCount,
  } = props;

  const scrollRef = useRef();

  // Scroll to top on page change
  useEffect(() => {
    scrollRef.current.scrollTo(0, 0);
  }, [currentPage]);

  return (
    <div className="w-full flex-grow overflow-hidden border border-default sm:rounded-lg">
      <div className="h-full flex flex-col overflow-hidden divide-y divide-gray-300">
        <div
          className="flex-grow w-full overflow-x-auto relative"
          ref={scrollRef}
        >
          <table className="min-w-full divide-y divide-default bg-white table-auto">
            <TableHeader
              columns={columns}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
            />
            <tbody className={`divide-y divide-default bg-white `}>
              {items.map((item) => (
                <TableRow
                  key={item.id}
                  item={item}
                  columns={columns}
                  selected={isSelected(item)}
                  onRender={onRowRender}
                  onClick={onRowClick}
                />
              ))}
            </tbody>
          </table>
          {items.length === 0 && emptyState}
          {loading && (
            <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50">
              <Loader stroke="#8D8D8D" width={24} strokeWidth={8} />
            </div>
          )}
          {children}
        </div>
        {isPaginated && (
          <TablePagination
            {...{
              currentPage,
              setCurrentPage,
              perPage,
              setPerPage: (perPage) => {
                setPerPage(perPage);
                setCurrentPage(1);
              },
              perPageOptions,
              total: totalCount,
            }}
          />
        )}
      </div>
    </div>
  );
}
