import { LinkIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { AutomationContext } from "~/contexts/automation-context";
import { UIContext } from "~/contexts/ui-context";
import { UserContext } from "~/contexts/user-context";

import formDataHeaders from "~/utils/formDataHeaders";
import headers from "~/utils/headers";
import scenarioStatusIcon from "~/utils/scenarioStatusIcon";
import AddActionMenu from "../components/automation/flow/AddActionMenu";

export default function AutomationProvider(props) {
  const { showMenu, showAlert, showPrompt, showError } = useContext(UIContext);
  const { organization } = useContext(UserContext);

  const { t } = useTranslation();

  const [scenarios, setScenarios] = useState([]);
  const [scenarioTemplates, setScenarioTemplates] = useState([]);

  const loadScenarios = async (params) => {
    const res = await axios.get("/api/automation/scenarios", { params });
    setScenarios(res.data);
    return res.data;
  };

  useEffect(loadScenarios, [organization.id]);

  const loadScenarioTemplates = useCallback(() => {
    axios.get("/api/automation/scenario_templates").then((res) => {
      setScenarioTemplates(res.data);
    });
  }, [setScenarioTemplates]);

  useEffect(loadScenarioTemplates, [organization.id]);

  const loadScenario = async (scenarioId) => {
    const res = await axios.get(`/api/automation/scenarios/${scenarioId}`);
    updateLocalScenario(scenarioId, res.data);
    return res.data;
  };

  const updateLocalScenario = (scenarioId, payload) => {
    setScenarios((scenarios) => {
      return scenarios.map((scenario) => {
        if (parseInt(scenario.id) === parseInt(scenarioId)) {
          return { ...scenario, ...payload };
        }
        return scenario;
      });
    });
  };

  const updateScenario = async (scenarioId, payload) => {
    try {
      const res = await axios.patch(
        `/api/automation/scenarios/${scenarioId}`,
        payload,
        headers(),
      );
      updateLocalScenario(scenarioId, res.data);
      return res.data;
    } catch (error) {
      const errorKey = error.response?.data?.errors?.base?.[0];
      if (errorKey) showError(errorKey);
    }
  };

  const createScenario = async (scenario) => {
    const res = await axios.post(
      "/api/automation/scenarios",
      scenario,
      headers(),
    );
    setScenarios((scenarios) => [...scenarios, res.data]);
    return res.data;
  };

  const duplicateScenario = async (scenarioId) => {
    const res = await axios.post(
      `/api/automation/scenarios/${scenarioId}/duplicate`,
      {},
      headers(),
    );
    setScenarios((scenarios) => [...scenarios, res.data]);
    return res.data;
  };

  const deleteScenario = async (scenarioId, callback = () => {}) => {
    showPrompt(t("automation.scenarios.delete_confirm"), async () => {
      await axios.delete(`/api/automation/scenarios/${scenarioId}`, headers());
      setScenarios(scenarios.filter((s) => s.id !== scenarioId));
      callback();
    });
  };

  const showRunScenarioMenu = (params = {}, callback = () => {}) => {
    showMenu({
      title: t("automation.scenarios.run"),
      actions: scenarios.map((scenario) => {
        return {
          label: (
            <div className="flex items-center space-x-1">
              {scenarioStatusIcon(scenario.active)}
              <div className="font-medium">{scenario.title}</div>
            </div>
          ),
          action: () => runScenario(scenario.id, params, callback),
        };
      }),
    });
  };

  const testScenario = async (scenario, contactId) => {
    const res = await axios.post(
      `/api/automation/scenarios/${scenario.id}/run_test`,
      { ...scenario, contact_id: contactId },
      headers(),
    );
    updateLocalScenario(scenario.id, res.data);
    return res.data;
  };

  const runScenario = (scenarioId, params, callback = () => {}) => {
    const scenario = scenarios.find((s) => s.id === scenarioId);
    showAlert({
      title: scenario.title,
      message: t("automation.scenarios.run_confirm"),
      onSubmit: () =>
        axios
          .post(
            `/api/automation/scenarios/${scenarioId}/run`,
            params,
            headers(),
          )
          .then(callback),
    });
  };

  const triggerLimited = async (scenarioId) => {
    const res = await axios.post(
      `/api/automation/scenarios/${scenarioId}/trigger_limited`,
      null,
      headers(),
    );
    updateLocalScenario(scenarioId, res.data);
    return res.data;
  };

  // Conversion links

  const [conversionLinks, setConversionLinks] = useState([]);

  const loadConversionLinks = async () => {
    const res = await axios.get("/api/automation/conversion_links");
    setConversionLinks(res.data);
  };

  useEffect(loadConversionLinks, [organization.id]);

  const saveConversionLink = async (link) => {
    if (link.id) {
      return updateConversionLink(link);
    }

    return createConversionLink(link);
  };

  const createConversionLink = async (link) => {
    const res = await axios.post(
      "/api/automation/conversion_links",
      link,
      headers(),
    );
    setConversionLinks((links) => [...links, res.data]);
    return res.data;
  };

  const updateConversionLink = async (link) => {
    const linkId = link.id;
    const res = await axios.patch(
      `/api/automation/conversion_links/${linkId}`,
      link,
      headers(),
    );
    setConversionLinks((links) =>
      links.map((l) => (l.id === linkId ? res.data : l)),
    );
    return res.data;
  };

  const saveUploadedImage = async (data, linkId) => {
    const res = await axios.patch(
      `/api/automation/conversion_links/${linkId}`,
      data,
      formDataHeaders(),
    );
    setConversionLinks((links) =>
      links.map((l) => (l.id === linkId ? res.data : l)),
    );
  };

  const destroyConversionLink = async (linkId) => {
    axios.delete(`/api/automation/conversion_links/${linkId}`, headers());
    setConversionLinks((links) => links.filter((l) => l.id !== linkId));
  };

  const getOpenGraphData = async (url) => {
    const res = await axios.get(
      `/api/automation/conversion_links/opengraph?url=${url}`,
    );
    return res.data;
  };

  const showSendConversionLinkMenu = (
    conversationIds,
    provider = "none",
    title = null,
  ) => {
    const links = conversionLinks.filter((l) => l.link_provider === provider);
    showMenu({
      title: title || t("automation.conversion_links.send"),
      actions: links.map((link) => {
        return {
          label: (
            <div className="flex items-center space-x-1">
              {link.icon_url ? (
                <img
                  src={link.icon_url}
                  className="w-4 object-center object-contain"
                />
              ) : (
                <div className="p-0.5">
                  <LinkIcon className="w-3 text-darker-gray" />
                </div>
              )}
              <div className="text-md font-medium">{link.title}</div>
            </div>
          ),
          action: () => sendConversionLink(link.id, conversationIds),
        };
      }),
    });
  };

  const sendConversionLink = (linkId, conversationIds) => {
    axios.post(
      `/api/automation/conversion_links/${linkId}/send_link`,
      {
        conversation_ids: conversationIds,
      },
      headers(),
    );
  };

  // Surveys

  const [surveys, setSurveys] = useState([]);

  const loadSurveys = async () => {
    const res = await axios.get("/api/automation/surveys");
    setSurveys(res.data);
  };

  const loadSurveyResults = async (surveyId, params = {}) => {
    const res = await axios.get(`/api/automation/surveys/${surveyId}/results`, {
      params,
    });
    return res.data;
  };

  useEffect(loadSurveys, [organization.id]);

  const saveSurvey = async (survey) => {
    if (survey.id) {
      return updateSurvey(survey);
    }

    return createSurvey(survey);
  };

  const createSurvey = async (survey) => {
    const res = await axios.post("/api/automation/surveys", survey, headers());
    setSurveys((surveys) => [...surveys, res.data]);
    return res.data;
  };

  const updateSurvey = async (survey) => {
    const res = await axios.patch(
      `/api/automation/surveys/${survey.id}`,
      survey,
      headers(),
    );
    setSurveys((surveys) =>
      surveys.map((s) => (s.id === survey.id ? res.data : s)),
    );
    return res.data;
  };

  const destroySurvey = async (surveyId) => {
    axios.delete(`/api/automation/surveys/${surveyId}`, headers());
    setSurveys((surveys) => surveys.filter((s) => s.id !== surveyId));
  };

  const testSurvey = async (survey, contactId) => {
    const res = await axios.post(
      `/api/automation/surveys/${survey.id}/run_test`,
      { ...survey, contact_id: contactId },
      headers(),
    );
    setSurveys((surveys) =>
      surveys.map((s) => (s.id === survey.id ? res.data : s)),
    );
    return res.data;
  };

  // Executions

  const loadScenarioExecutions = async (params = {}) => {
    const res = await axios.get(`/api/automation/scenario_executions`, {
      params,
    });
    return res.data;
  };

  const retryScenarioExecution = async (executionId) => {
    const res = await axios.post(
      `/api/automation/scenario_executions/${executionId}/retry`,
      null,
      headers(),
    );
    return res.data;
  };

  // Actions

  const loadActionExecutions = async (
    executionType = "scenario",
    executionId,
  ) => {
    const res = await axios.get(`/api/automation/action_executions`, {
      params: {
        execution_type: executionType,
        execution_id: executionId,
      },
    });
    return res.data;
  };

  const updateActionExecution = async (executionId, payload) => {
    const res = await axios.patch(
      `/api/automation/action_executions/${executionId}`,
      payload,
      headers(),
    );
    return res.data;
  };

  const retryActionExecution = async (executionId) => {
    const res = await axios.post(
      `/api/automation/action_executions/${executionId}/retry`,
      null,
      headers(),
    );
    return res.data;
  };

  const rerunActionExecution = async (executionId) => {
    const res = await axios.post(
      `/api/automation/action_executions/${executionId}/rerun`,
      null,
      headers(),
    );
    return res.data;
  };

  const automationValues = {
    scenarios,
    loadScenarios,
    scenarioTemplates,
    loadScenarioTemplates,
    loadScenario,
    createScenario,
    duplicateScenario,
    updateLocalScenario,
    updateScenario,
    deleteScenario,
    showRunScenarioMenu,
    testScenario,
    runScenario,
    triggerLimited,
    conversionLinks,
    saveConversionLink,
    saveUploadedImage,
    destroyConversionLink,
    getOpenGraphData,
    showSendConversionLinkMenu,
    sendConversionLink,
    surveys,
    loadSurveyResults,
    saveSurvey,
    testSurvey,
    destroySurvey,
    loadScenarioExecutions,
    retryScenarioExecution,
    loadActionExecutions,
    updateActionExecution,
    retryActionExecution,
    rerunActionExecution,
  };

  return (
    <AutomationContext.Provider value={automationValues}>
      {props.children}
    </AutomationContext.Provider>
  );
}
