import React, { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FlowContext } from "~/contexts/flow-context";
import Textarea from "react-expanding-textarea";
import classNames from "../../../../utils/classNames";
import { PlusIcon, XMarkIcon } from "@heroicons/react/20/solid";
import AutoInput from "../../../utils/AutoInput";
import CustomNode from "./CustomNode";
import { UIContext } from "../../../../contexts/ui-context";
import ContactPropertyPickerField from "~/components/fields/ContactPropertyPickerField";

export default function AskQuestionNode(props) {
  const { t } = useTranslation();

  const { selected, data } = props;
  const { id, content, options, editable } = data;

  const { ask_question_options = [], contact_property } = options;

  const { actions, editAction } = useContext(FlowContext);
  const { showPrompt } = useContext(UIContext);

  const handleAddOption = () => {
    editAction(id, {
      options: {
        ...options,
        ask_question_options: [...ask_question_options, { title: "" }],
      },
    });
  };

  const handleRemoveOption = (index) => {
    // Check if there are any children under this option
    const branchChildren = actions.filter(
      (a) =>
        a.parent_id?.toString() === id.toString() &&
        a.parent_branch === `option_${index}`,
    );
    if (branchChildren.length > 0) {
      showPrompt(
        t(
          "automation.scenarios.actions.ask_question.delete_reply_confirm_title",
        ),
        () => {
          editAction(id, {
            options: {
              ...options,
              ask_question_options: ask_question_options.filter(
                (_, i) => i !== index,
              ),
            },
          });
        },
        () => {},
        t(
          "automation.scenarios.actions.ask_question.delete_reply_confirm_message",
        ),
      );
    } else {
      editAction(id, {
        options: {
          ...options,
          ask_question_options: ask_question_options.filter(
            (_, i) => i !== index,
          ),
        },
      });
    }
  };

  const handleChangeOption = (index, value) => {
    const title = value.slice(0, 20);
    editAction(id, {
      options: {
        ...options,
        ask_question_options: options.ask_question_options.map((option, i) =>
          i === index ? { ...option, title } : option,
        ),
      },
    });
  };

  const inputRef = useRef(null);

  useEffect(() => content === "" && inputRef.current.focus(), [editable]);

  return (
    <CustomNode selected={selected}>
      <div
        className={`w-full py-3 px-3 bg-white border-2 rounded-2xl nodrag ring-1 ring-indigo-400 ring-offset-1 ring-opacity-0 hover:ring-opacity-100 transition-all space-y-2 ${
          selected ? "ring-opacity-100 z-50" : null
        }`}
      >
        <div className="text-md font-medium">
          {t("automation.scenarios.actions.ask_question.title")}
        </div>
        <div className="text-2sm text-darker-gray">
          {t("automation.scenarios.actions.ask_question.description")}
        </div>
        <div className="flex flex-col items-end mt-4">
          <Textarea
            ref={inputRef}
            className={classNames(
              "nodrag nopan block w-[] text-message border-none outline-none resize-none focus:outline-none whitespace-pre-wrap ring-offset-1 ring-indigo-400 ring-opacity-0 hover:ring-opacity-100",
            )}
            placeholder={t(
              "automation.scenarios.actions.ask_question.question_placeholder",
            )}
            value={content}
            onChange={(evt) => editAction(id, { content: evt.target.value })}
            autoFocus={content === ""}
          />
        </div>
        <div className="flex flex-col items-end space-y-2">
          {ask_question_options?.map((option, index) => (
            <div
              key={index}
              className="flex items-center space-x-1 border rounded-2xl bg-white pl-2.5 pr-2 py-1"
            >
              <AutoInput
                className="min-w-[20px] border-none bg-none outline-none p-0 focus:outline-none focus:ring-0 text-sm"
                value={option.title}
                onChange={(evt) => handleChangeOption(index, evt.target.value)}
                autoFocus={option.title === ""}
                placeholder={t(
                  "automation.scenarios.actions.ask_question.option_placeholder",
                )}
              />
              <XMarkIcon
                className="w-4 opacity-75 transition-opacity hover:opacity-100 cursor-pointer"
                onClick={() => handleRemoveOption(index)}
              />
            </div>
          ))}
          {ask_question_options?.length < 3 && (
            <button
              className="nodrag nopan flex-shrink-0 flex items-center space-x-1.5 border rounded-2xl bg-white pl-2 pr-2.5 py-1 text-sm whitespace-nowrap hover:bg-neutral-50 transition-colors"
              onClick={handleAddOption}
            >
              <PlusIcon className="w-4" />
              {t("automation.scenarios.actions.ask_question.add_reply")}
            </button>
          )}
        </div>
        <div className="flex items-center space-x-2 mb-2 overflow-hidden bg-neutral-100 rounded-lg p-2 pl-3">
          <div className="text-xs flex-grow whitespace-nowrap flex-shrink-0">
            {t("automation.scenarios.actions.ask_question.save_reply_to")}
          </div>
          <ContactPropertyPickerField
            value={contact_property}
            onChange={(value) =>
              editAction(id, {
                options: { ...options, contact_property: value },
              })
            }
          />
        </div>
      </div>
    </CustomNode>
  );
}
