import React, { useContext, useEffect, useState } from "react";
import { DateTime } from "luxon";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChatBubbleLeftRightIcon,
  ArrowPathIcon,
} from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

import ContactHeader from "~/components/contacts/ContactHeader";
import InlineTooltip from "~/components/shared/InlineTooltip";
import RelativeDate from "~/components/utils/RelativeDate";
import SkeletonLoader from "~/components/utils/SkeletonLoader";
import classNames from "~/utils/classNames";
import ExecutionStatusIcon from "./ExecutionStatusIcon";
import ActionExecution from "./ActionExecution";
import { sortBy } from "lodash";
import Button from "~/components/elements/Button";
import { AutomationContext } from "~/contexts/automation-context";
import { SubscriptionContext } from "~/contexts/subscription-context";

export default function ContactScenarioExecution(props) {
  const { t } = useTranslation();

  const { execution, setExecution, loading = false, open, onClick } = props;

  const { status, contact, conversation_id, created_at } = execution || {};

  const { limitReached } = useContext(SubscriptionContext);
  const { loadActionExecutions, retryScenarioExecution } =
    useContext(AutomationContext);

  const [retrying, setRetrying] = useState(false);

  const handleRetryScenarioExecution = async () => {
    setRetrying(true);
    const res = await retryScenarioExecution(execution.id);
    setExecution(res);
    setRetrying(false);
  };

  const [actionExecutions, setActionExecutions] = useState([]);

  const handleLoadActionExecutions = async () => {
    const res = await loadActionExecutions("scenario", execution.id);
    setActionExecutions(res);
  };

  useEffect(() => {
    if (open && actionExecutions.length === 0) handleLoadActionExecutions();
  }, [open]);

  return (
    <div
      className={classNames(
        "border rounded-xl transition-colors group",
        open ? "border-primary" : "hover:border-primary",
      )}
    >
      <div
        className="py-3 px-4 flex items-center justify-between cursor-pointer"
        onClick={onClick}
      >
        <div className="flex items-center space-x-4">
          {!loading ? (
            <ExecutionStatusIcon status={status} size="md" />
          ) : (
            <SkeletonLoader round width={24} height={24} />
          )}
          <ContactHeader contact={contact} loading={loading} showFolder />
        </div>
        <div className="flex items-center space-x-3">
          <Button
            size="small"
            label={t("automation.scenarios.executions.view_conversation")}
            to={"/inbox/conversations/" + conversation_id}
            icon={ChatBubbleLeftRightIcon}
            className={open ? "visible" : "hidden"}
          />
          {loading ? (
            <SkeletonLoader width={80} height={20} />
          ) : (
            <InlineTooltip
              text={t("automation.scenarios.executions.started", {
                date: DateTime.fromISO(created_at).toLocaleString(
                  DateTime.DATETIME_MED,
                ),
              })}
              align="right"
            >
              <div className="text-sm text-dark-gray">
                <RelativeDate date={created_at} />
              </div>
            </InlineTooltip>
          )}
          {open ? (
            <ChevronDownIcon className="w-5" />
          ) : (
            <ChevronLeftIcon className="w-5" />
          )}
        </div>
      </div>
      {open && (
        <div className="px-6 pb-2 pt-1 flex flex-col items-start space-y-2 relative">
          {status == "limited" && (
            <div className="bg-warning p-2 rounded mb-2 space-y-2">
              <div className="text-md">
                {t("automation.scenarios.executions.limited")}
              </div>
              {!limitReached && (
                <Button
                  size="extra-small"
                  icon={ArrowPathIcon}
                  label={t("shared.retry")}
                  loading={retrying}
                  onClick={handleRetryScenarioExecution}
                />
              )}
            </div>
          )}
          <div className="divide-y self-stretch">
            {sortBy(actionExecutions, "created_at").map((actionExecution) => (
              <ActionExecution
                key={actionExecution.id}
                execution={actionExecution}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
