import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MediaPickerField from "~/components/fields/MediaPickerField";
import Button from "~/components/elements/Button";
import Input from "~/components/elements/Input";
import StoryPickerField from "~/components/fields/StoryPickerField";
import { AutomationContext } from "~/contexts/automation-context";
import { CampaignsContext } from "~/contexts/campaigns-context";
import { ContactsContext } from "~/contexts/contacts-context";
import FolderFilter from "./FolderFilter";

const dateRanges = [
  "today",
  "yesterday",
  "last_7_days",
  "last_30_days",
  "last_90_days",
  "last_365_days",
  "all_time",
  "custom",
];

const presenceOptions = ["is_present", "is_not_present"];

const inclusionOptions = ["contains", "does_not_contain"];

const comparisonOptions = ["is_equal", "is_above", "is_below"];

export default function ContactFilter(props) {
  const { t } = useTranslation();

  const { filter, onChange, onRemove, disabled } = props;

  const { languages, loadLanguages } = useContext(ContactsContext);
  const { scenarios, loadScenarios } = useContext(AutomationContext);
  const { campaigns, loadCampaigns } = useContext(CampaignsContext);

  const handleChange = (key) => (value) => {
    onChange({ ...filter, [key]: value });
  };

  // This function generates another function that returns a select element
  const operatorSelect = (options) => () => {
    return (
      <select
        className="input-select"
        disabled={disabled}
        onChange={(evt) => onChange({ ...filter, operator: evt.target.value })}
        value={filter.operator || "is_present"}
      >
        {options.map((option, index) => (
          <option value={option} key={index}>
            {t(`contacts.filters.options.operators.${option}`)}
          </option>
        ))}
      </select>
    );
  };

  // TODO merge this with filterTypes.js
  const filter_categories = {
    any_engagement: {
      options: [],
      hasDateField: true,
    },
    message: {
      options: [],
      hasDateField: true,
    },
    referral_ad: {
      options: [],
      hasDateField: true,
    },
    media_mention: {
      options: [],
      hasDateField: true,
    },
    clicked_conversion_link: {
      options: [],
      hasDateField: true,
    },
    appointment: {
      options: [],
      hasDateField: true,
    },
    payment: {
      options: [
        () => (
          <select
            className="input-select"
            disabled={disabled}
            onChange={(evt) =>
              onChange({ ...filter, payment_status: evt.target.value })
            }
            value={filter.payment_status || "any"}
          >
            {[
              "any",
              "awaiting",
              "paid",
              "cancelled",
              "manual",
              "attempted",
              "failed",
              "refunded",
            ].map((option, index) => (
              <option value={option} key={index}>
                {t("payments.labels." + option)}
              </option>
            ))}
          </select>
        ),
      ],
      hasDateField: true,
      hasValueField: true,
    },
    is_follower: {
      options: [
        () => (
          <select
            className="input-select"
            disabled={disabled}
            onChange={(evt) =>
              onChange({ ...filter, operator: evt.target.value })
            }
            value={filter.operator || "is_true"}
          >
            <option value="is_true">
              {t("contacts.filters.options.is_follower.is_true")}
            </option>
            <option value="is_false">
              {t("contacts.filters.options.is_follower.is_false")}
            </option>
          </select>
        ),
      ],
    },
    follower_count: {
      options: [operatorSelect(comparisonOptions)],
      hasValueField: true,
    },
    folder: {
      component: FolderFilter,
    },
    campaign: {
      options: [
        () => (
          <select
            className="input-select"
            disabled={disabled}
            onChange={(evt) =>
              onChange({ ...filter, campaign_id: evt.target.value })
            }
            value={filter.campaign_id || ""}
          >
            <option>{t("contacts.filters.options.campaigns.any")}</option>
            {campaigns.map((campaign, index) => (
              <option value={campaign.id} key={index}>
                {campaign.title}
              </option>
            ))}
          </select>
        ),
      ],
      hasDateField: true,
    },
    email: {
      options: [operatorSelect([...presenceOptions, ...inclusionOptions])],
      hasValueField: true,
    },
    name: {
      options: [operatorSelect([...presenceOptions, ...inclusionOptions])],
      hasValueField: true,
    },
    username: {
      options: [operatorSelect([...presenceOptions, ...inclusionOptions])],
      hasValueField: true,
    },
    phone: {
      options: [operatorSelect([...presenceOptions, ...inclusionOptions])],
      hasValueField: true,
    },
    language: {
      options: [
        () => (
          <select
            className="input-select"
            disabled={disabled}
            onChange={(evt) => onChange({ ...filter, value: evt.target.value })}
            value={filter.value || "en"}
          >
            {languages.map((language, index) => (
              <option value={language} key={language}>
                {t("languages." + language)}
              </option>
            ))}
          </select>
        ),
      ],
    },
    story_reply: {
      options: [
        () => (
          <StoryPickerField
            value={filter.story_id || ""}
            onChange={handleChange("story_id")}
          />
        ),
      ],
      hasDateField: true,
    },
    story_mention: {
      options: [],
      hasDateField: true,
    },
    comment: {
      options: [
        () => (
          <MediaPickerField
            value={filter.media_id || ""}
            onChange={handleChange("media_id")}
          />
        ),
      ],
      hasDateField: true,
    },
    scenario: {
      options: [
        () => (
          <select
            className="input-select"
            disabled={disabled}
            onChange={(evt) =>
              onChange({ ...filter, scenario_id: evt.target.value })
            }
            value={filter.scenario_id || ""}
          >
            <option>{t("contacts.filters.options.scenarios.any")}</option>
            {scenarios.map((scenario, index) => (
              <option value={scenario.id} key={index}>
                {scenario.title}
              </option>
            ))}
          </select>
        ),
      ],
      hasDateField: true,
    },
  };

  const filterCategory = filter_categories[filter.filter_type] || {};

  // load data for specific filters
  useEffect(() => {
    if (filter.filter_type === "language") loadLanguages();
    if (filter.filter_type === "scenario") loadScenarios();
    if (filter.filter_type === "campaign") loadCampaigns();
  }, [filter.filter_type]);

  if (filterCategory.component) {
    const Component = filterCategory.component;
    return (
      <Component
        filter={filter}
        onChange={onChange}
        onRemove={onRemove}
        disabled={disabled}
      />
    );
  }

  return (
    <div className="flex items-center space-x-2">
      <span className="font-semibold text-md">
        {t(`contacts.filters.categories.${filter.filter_type}.description`)}
      </span>

      {filterCategory.hasDateField && (
        <select
          className="input-select"
          disabled={disabled}
          onChange={(evt) =>
            onChange({ ...filter, date_range: evt.target.value })
          }
          value={filter.date_range}
        >
          {dateRanges.map((range, index) => (
            <option value={range} key={index}>
              {t(`contacts.filters.options.date_ranges.${range}`)}
            </option>
          ))}
        </select>
      )}

      {/* Filter options */}
      {filterCategory.options?.map((filterOption, index) => (
        <div key={index}>{filterOption()}</div>
      ))}
      {filterCategory.hasValueField &&
        [
          "is_equal",
          "is_above",
          "is_below",
          "contains",
          "does_not_contain",
        ].includes(filter.operator) && (
          <Input
            disabled={disabled}
            onChange={(value) => onChange({ ...filter, value })}
            value={filter?.value}
            debounce
          />
        )}
      <Button
        label={t("contacts.filters.remove_filter")}
        disabled={disabled}
        onClick={onRemove}
      />
    </div>
  );
}
