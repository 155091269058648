import React, { useContext } from "react";
import { FlowContext } from "~/contexts/flow-context";
import CustomNode from "./CustomNode";
import FolderPickerField from "~/components/fields/FolderPickerField";
import { useTranslation } from "react-i18next";

export default function FolderNode(props) {
  const { t } = useTranslation();

  const { selected, data, menuVisible } = props;
  const { id, options } = data;

  const { folder_id, folder_action } = options;

  const { editAction } = useContext(FlowContext);

  const handleChangeAction = (e) => {
    editAction(id, { options: { ...options, folder_action: e.target.value } });
  };

  const handlePickFolder = (folderId) => {
    editAction(id, {
      menuVisible: false,
      options: { ...options, folder_id: folderId },
    });
  };

  return (
    <>
      <CustomNode selected={selected}>
        <div
          className={`py-3 px-3 bg-white border-2 rounded-2xl nodrag ring-1 ring-indigo-400 ring-offset-1 ring-opacity-0 hover:ring-opacity-100 transition-all flex flex-col space-y-3 items-center text-md whitespace-nowrap ${
            selected ? "ring-opacity-100 z-50" : null
          }`}
        >
          <select
            className="input-select"
            value={folder_action}
            onChange={handleChangeAction}
          >
            {["add", "remove"].map((action) => (
              <option key={action} value={action}>
                {t("automation.scenarios.actions.folder." + action)}
              </option>
            ))}
          </select>

          <FolderPickerField
            value={folder_id}
            onChange={handlePickFolder}
            menuVisible={menuVisible}
          />
        </div>
      </CustomNode>
    </>
  );
}
