import React from "react";
import CustomNode from "./CustomNode";
import { useTranslation } from "react-i18next";

export default function AskQuestionOptionNode(props) {
  const { t } = useTranslation();

  const { data } = props;
  const { title } = data;

  return (
    <CustomNode noEdit>
      <div className="space-y-2 flex flex-col items-center">
        <div className="text-3xs uppercase font-bold text-neutral-400">
          {t("automation.scenarios.actions.ask_question.when_reply")}
        </div>
        <div className="flex items-center space-x-1 border rounded-2xl bg-white pl-2.5 pr-2 py-1">
          <div className="text-sm">{title}</div>
        </div>
      </div>
    </CustomNode>
  );
}
