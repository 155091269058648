import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import RelativeDate from "~/components/utils/RelativeDate";
import {
  BoltIcon,
  DocumentArrowDownIcon,
  PencilSquareIcon,
  TrashIcon,
  ListBulletIcon,
  ArrowTurnDownRightIcon,
} from "@heroicons/react/20/solid";
import TriggerBadge from "../flow/triggers/TriggerBadge";
import Toggle from "~/components/shared/Toggle";
import { AutomationContext } from "~/contexts/automation-context";
import { UIContext } from "~/contexts/ui-context";
import ButtonWithDropdown from "~/components/elements/ButtonWithDropdown";
import { Link, useNavigate } from "react-router-dom";
import { FireIcon } from "@heroicons/react/24/solid";
import SkeletonLoader from "~/components/utils/SkeletonLoader";
import GrowthRate from "~/components/utils/GrowthRate";
import Badge from "~/components/elements/Badge";
import ScenarioTriggerBadges from "./ScenarioTriggerBadges";

export default function ScenarioListItem(props) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { scenario } = props;

  const {
    id,
    active,
    title,
    triggers,
    actions,
    metrics,
    updated_at,
    last_executed_at,
    loaded,
    is_template,
    limited_executions,
  } = scenario;

  const {
    scenarios,
    loadScenario,
    updateScenario,
    duplicateScenario,
    deleteScenario,
  } = useContext(AutomationContext);
  const { showPrompt } = useContext(UIContext);

  const handleToggleStatus = (newStatus) => {
    showPrompt(
      newStatus
        ? t("automation.scenarios.start_confirm")
        : t("automation.scenarios.pause_confirm"),
      () => {
        updateScenario(id, { active: newStatus });
      },
    );
  };

  // actions that trigger another scenario
  const triggerScenarioActions =
    actions?.filter((action) => action.action_type === "trigger_scenario") ||
    [];

  // only load if hasn!t been loaded yet
  useEffect(() => !loaded && loadScenario(id), [id, loaded]);

  return (
    <tr key={id} className="align-top">
      <td>
        <Link
          className="block whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0"
          to={`/automation/scenarios/${id}/history`}
        >
          <div className="flex items-center space-x-2 mb-1.5">
            <div className="text-base font-medium text-neutral-900 truncate">
              {title}
            </div>
            {!!is_template && (
              <Badge
                label={t("automation.scenarios.template")}
                color="blue"
                className="ml-1.5 -mt-px"
                size="sm"
              />
            )}
            {!!limited_executions && (
              <Badge
                label={t("automation.scenarios.limit_reached")}
                size="sm"
                color="yellow"
              />
            )}
          </div>
          {triggers ? (
            <ScenarioTriggerBadges triggers={triggers} />
          ) : (
            <SkeletonLoader width={100} height={20} />
          )}
          {triggerScenarioActions.map((action) => (
            <div className="mt-2 flex items-center">
              <ArrowTurnDownRightIcon className="w-4 text-neutral-500 mr-1" />
              <BoltIcon className="w-3 text-neutral-500 mr-0.5" />
              <div className="text-sm text-neutral-500">
                {
                  scenarios.find((s) => s.id === action.options.scenario_id)
                    ?.title
                }
              </div>
            </div>
          ))}
        </Link>
      </td>
      <td>
        <Link
          className="block whitespace-nowrap px-3 py-5 text-sm text-neutral-500"
          to={`/automation/scenarios/${id}/history`}
        >
          <div className="flex items-start space-x-2">
            <BoltIcon className="h-5 w-5 text-darker-gray" />
            <div className="space-y-1">
              {metrics ? (
                <div className="text-neutral-900">
                  {t("automation.scenarios.table.items.last_7_days", {
                    count: metrics.executions.last_7_days,
                  })}
                </div>
              ) : (
                <SkeletonLoader width={120} height={20} />
              )}
              {metrics ? (
                <div className="text-sm text-neutral-500">
                  {t("automation.scenarios.table.items.total", {
                    count: metrics.executions.total,
                  })}
                  <GrowthRate
                    value={metrics.executions.trend}
                    className="ml-3 inline-block text-xs font-medium"
                  />
                </div>
              ) : (
                <SkeletonLoader width={90} height={20} />
              )}
            </div>
          </div>
        </Link>
      </td>
      <td>
        <Link
          className="block whitespace-nowrap px-3 py-5 text-sm text-neutral-500"
          to={`/automation/scenarios/${id}/history`}
        >
          <div className="flex items-start space-x-2">
            <FireIcon className="h-5 w-5 text-darker-gray" />
            <div className="space-y-1">
              {metrics ? (
                <div className="text-neutral-900">
                  {t("automation.scenarios.table.items.last_7_days", {
                    count: metrics.conversions.last_7_days,
                  })}
                </div>
              ) : (
                <SkeletonLoader width={120} height={20} />
              )}
              {metrics ? (
                <div className="textsm text-neutral-500">
                  {t("automation.scenarios.table.items.total", {
                    count: metrics.conversions.total,
                  })}
                  <GrowthRate
                    value={metrics.conversions.trend}
                    className="ml-3 inline-block text-xs font-medium"
                  />
                </div>
              ) : (
                <SkeletonLoader width={70} height={20} />
              )}
            </div>
          </div>
        </Link>
      </td>
      <td className="whitespace-nowrap px-3 py-5 text-sm text-neutral-500">
        <RelativeDate date={updated_at} />
      </td>
      <td className="whitespace-nowrap px-3 py-5 text-sm text-neutral-500">
        <RelativeDate date={last_executed_at} />
      </td>
      <td className="whitespace-nowrap px-3 py-7 text-sm text-neutral-500">
        <Toggle value={active} onChange={handleToggleStatus} />
      </td>
      <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
        <ButtonWithDropdown
          label={t("shared.edit")}
          icon={PencilSquareIcon}
          actions={[
            {
              label: t("automation.scenarios.view_history"),
              icon: ListBulletIcon,
              onClick: () => navigate(`/automation/scenarios/${id}/history`),
            },
            {
              label: t("shared.duplicate"),
              icon: DocumentArrowDownIcon,
              onClick: () => duplicateScenario(id),
            },
            {
              label: t("shared.delete"),
              icon: TrashIcon,
              onClick: () => deleteScenario(id),
              className: "!text-red-500",
            },
          ]}
          onClick={() => navigate(`/automation/scenarios/${id}`)}
        />
      </td>
    </tr>
  );
}
