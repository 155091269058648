import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FlowContext } from "~/contexts/flow-context";
import CustomNode from "./CustomNode";
import Input from "~/components/elements/Input";
import FolderPickerField from "~/components/fields/FolderPickerField";
import ButtonSelect from "../../../elements/ButtonSelect";

const conditionActions = ["stop", "continue"];
const conditionTypes = ["is_follower", "is_in_folder", "message_contains"];
const conditionOptions = ["emojis", "keywords"];

export default function ScenarioConditionNode(props) {
  const { t } = useTranslation();

  const { selected, data } = props;
  const { id, options } = data;

  const {
    condition_action,
    condition_type,
    condition_option,
    condition_value,
    folder_id,
  } = options;

  const { editAction } = useContext(FlowContext);

  // This function generates a function
  const handleChangeAction = (option) => (value) => {
    editAction(id, { options: { ...options, [option]: value } });
  };

  return (
    <CustomNode selected={selected}>
      <div
        className={`py-3 px-3 bg-white border-2 rounded-2xl nodrag ring-1 ring-indigo-400 ring-offset-1 ring-opacity-0 hover:ring-opacity-100 transition-all space-y-2 ${
          selected ? "ring-opacity-100 z-50" : null
        }`}
      >
        <div className="text-md font-medium">
          {t("automation.scenarios.actions.scenario_condition.title")}
        </div>
        <div className="text-2sm text-darker-gray">
          {t("automation.scenarios.actions.scenario_condition.description")}
        </div>
        <div className="flex flex-col space-y-2">
          <ButtonSelect
            value={condition_action}
            onChange={handleChangeAction("condition_action")}
            options={conditionActions.map((action) => ({
              label: t(
                "automation.scenarios.actions.scenario_condition." + action,
              ),
              value: action,
            }))}
          />
          <select
            className="input-select"
            value={condition_type}
            onChange={(evt) =>
              handleChangeAction("condition_type")(evt.target.value)
            }
          >
            {conditionTypes.map((type) => (
              <option key={type} value={type}>
                {t("automation.scenarios.actions.scenario_condition." + type)}
              </option>
            ))}
          </select>
          {condition_type === "is_in_folder" && (
            <div className="flex items-center justify-start space-x-2">
              <div className="text-2sm">
                {t("automation.scenarios.actions.scenario_condition.folder")}
              </div>
              <FolderPickerField
                value={folder_id}
                onChange={handleChangeAction("folder_id")}
              />
            </div>
          )}
          {condition_type === "message_contains" && (
            <div className="flex space-x-2 items-center">
              <select
                className="input-select"
                value={condition_option}
                onChange={(evt) =>
                  handleChangeAction("condition_option")(evt.target.value)
                }
              >
                {conditionOptions.map((option) => (
                  <option key={option} value={option}>
                    {t(
                      "automation.scenarios.actions.scenario_condition.condition_options." +
                        option,
                    )}
                  </option>
                ))}
              </select>
              {condition_option == "keywords" && (
                <Input
                  placeholder={t(
                    "automation.scenarios.actions.scenario_condition.message_contains_placeholder",
                  )}
                  value={condition_value}
                  onChange={handleChangeAction("condition_value")}
                  className="flex-grow"
                />
              )}
            </div>
          )}
        </div>
      </div>
    </CustomNode>
  );
}
