import { PlusCircleIcon } from "@heroicons/react/20/solid";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import CustomNode from "./CustomNode";
import { FlowContext } from "~/contexts/flow-context";

export default function AddActionNode(props) {
  const { t } = useTranslation();

  const { data } = props;

  const { parent_id, parent_branch } = data;

  const { addAction, showAddActionMenu } = useContext(FlowContext);

  const handleAddAction = (action) => {
    addAction(action, parent_id == "trigger" ? null : parent_id, parent_branch);
  };

  return (
    <CustomNode last noEdit>
      <div
        className="py-3 px-4 bg-white border-2 border-medium-gray text-dark-gray border-dashed rounded-2xl cursor-pointer nodrag opacity-75 hover:opacity-100 transition-opacity flex space-x-2 items-center"
        onClick={() =>
          showAddActionMenu({
            onSubmit: handleAddAction,
          })
        }
      >
        <PlusCircleIcon className="w-6" />
        <div className="text-lg font-medium text-center">
          {t(`automation.scenarios.add_step`)}
        </div>
      </div>
    </CustomNode>
  );
}
