import React from "react";
import { useTranslation } from "react-i18next";
import ContactsTable from "./ContactsTable";
import FullScreen from "~/components/utils/FullScreen";
import PageTitle from "~/components/shared/PageTitle";
import { useNavigate, useParams } from "react-router-dom";
import Conversation from "../inbox/Conversation";
import Modal from "../shared/Modal";
import EditContact from "./EditContact";
import ContactProperties from "./properties/ContactProperties";
import Button from "../elements/Button";
import {
  DocumentArrowDownIcon,
  IdentificationIcon,
} from "@heroicons/react/20/solid";
import ContactPage from "./ContactPage";

export default function ContactsPage(props) {
  const { t } = useTranslation();

  const { showProperties } = props;

  const { contactId } = useParams();
  const navigate = useNavigate();

  return (
    <FullScreen className="relative sm:!h-screen flex-grow flex flex-col overflow-hidden px-0 sm:px-6 lg:px-8 py-3 sm:pt-6 ">
      <div className="flex justify-between items-center">
        <PageTitle text={t("contacts.title")} className="px-3 sm:px-0" />
        <div className="flex items-center space-x-2">
          <Button
            onClick={() => navigate("/contacts/properties")}
            label={t("contacts.properties.title")}
            icon={IdentificationIcon}
          />
          <Button
            label={t("contacts.export")}
            icon={DocumentArrowDownIcon}
            target="_blank"
            href={`/export/contacts`}
          />
        </div>
      </div>
      <ContactsTable />
      {contactId && (
        <div className="absolute z-30 inset-0 flex justify-end">
          <div
            className="absolute inset-0 bg-black opacity-20"
            onClick={() => navigate("/contacts")}
          />
          <div className="relative w-full max-w-5xl h-full sm:ml-20 bg-white border-l shadow-lg">
            <ContactPage contactId={contactId} />
          </div>
        </div>
      )}
      {showProperties && (
        <Modal onClose={() => navigate("/contacts")}>
          <ContactProperties onClose={() => navigate("/contacts")} />
        </Modal>
      )}
    </FullScreen>
  );
}
